var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-list",
              { staticClass: "rounded-borders", attrs: { bordered: "" } },
              [
                _c(
                  "q-expansion-item",
                  {
                    attrs: {
                      dense: "",
                      dark: "",
                      "default-opened": "",
                      icon: "account_tree",
                      "header-class": "bg-blue-grey-6 text-white",
                      "expand-icon-class": "text-white",
                      label: _vm.$language("공정 정보"),
                    },
                  },
                  [
                    _c("q-separator"),
                    _c("q-card", { staticClass: "q-pa-sm" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-3 col-md-2" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "평가명",
                                value: _vm.assessPlan.assessmentName,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-3 col-md-2" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "평가기법",
                                value: _vm.assessPlan.ramTechniqueName,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-3 col-md-2" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "평가대상공정",
                                value: _vm.process.processName,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-3 col-md-2" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "작업발생주기",
                                value: _vm.process.workCycleName,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-3 col-md-2" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "작업시간(1회)",
                                value: _vm.process.workingTime,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            class: [
              _vm.scenarioViewGroup === "L"
                ? "col-12"
                : "col-xs-12 col-sm-6 col-md-4 col-lg-4",
              "scenario-list",
            ],
          },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "시나리오 목록",
                  columns: _vm.gridColumns,
                  gridHeight: _vm.setheight,
                  data: _vm.process.scenarios,
                  merge: _vm.scenarioViewGroup === "L" ? _vm.grid.merge : [],
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: _vm.editable && !_vm.disabled,
                  isExcelDown: _vm.scenarioViewGroup === "L",
                  isFullScreen: _vm.scenarioViewGroup === "L",
                  customTrClass: _vm.setTrClass,
                  selection: "multiple",
                  rowKey: "ramKrasAssessScenarioId",
                },
                on: {
                  callbackProxy: _vm.callbackProxy,
                  innerBtnClicked: _vm.innerBtnClicked,
                  "table-data-change": _vm.tableDataChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c(
                          "q-btn-group",
                          {
                            staticClass: "scenario-view-group",
                            attrs: { outline: "" },
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "L",
                                  color:
                                    _vm.scenarioViewGroup === "L"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "view_list",
                                },
                                on: { click: _vm.listView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$language("목록형")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "C",
                                  color:
                                    _vm.scenarioViewGroup === "C"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "space_dashboard",
                                },
                                on: { click: _vm.cardView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$language("카드형")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "customCol"
                          ? [
                              _c(_vm.imprComponent, {
                                tag: "component",
                                attrs: {
                                  col: col,
                                  props: props,
                                  inputEditable:
                                    _vm.editable &&
                                    Boolean(props.row.ramKrasAssessScenarioId),
                                  requestContentsCols: _vm.requestContentsCols,
                                  tableKey: "ramKrasAssessScenarioId",
                                  ibmTaskTypeCd: "ITT0000020",
                                  ibmTaskUnderTypeCd: "ITTU000025",
                                },
                                on: { imprChange: _vm.imprChange },
                              }),
                            ]
                          : col.name === "item"
                          ? [
                              _c(
                                "q-item",
                                { staticClass: "card-scenario-list" },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        { staticClass: "scenario-card-title" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              `${props.row["jobName"]} - ${props.row["riskHazardNameClass"]}`
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    { attrs: { side: "" } },
                                    [
                                      _c("q-icon", {
                                        staticClass: "text-primary",
                                        staticStyle: { "font-size": "20px" },
                                        attrs: { name: "arrow_forward_ios" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(props.row)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : col.name === "riskHazardPicture"
                          ? [
                              _c("q-icon", {
                                staticClass: "text-success",
                                staticStyle: {
                                  "font-size": "30px",
                                  cursor: "pointer",
                                },
                                attrs: { name: "image" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openHazardPicture(props)
                                  },
                                },
                              }),
                            ]
                          : col.name === "picture"
                          ? [
                              _c("q-icon", {
                                staticClass: "text-primary",
                                staticStyle: {
                                  "font-size": "30px",
                                  cursor: "pointer",
                                },
                                attrs: { name: "image" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openImprPicture(props)
                                  },
                                },
                              }),
                            ]
                          : col.name === "addingRiskManagementActivities"
                          ? [
                              _c("c-textarea-column", {
                                attrs: {
                                  editable: _vm.editable,
                                  disabled: _vm.disabled,
                                  props: props,
                                  col: col,
                                },
                                model: {
                                  value:
                                    props.row["addingRiskManagementActivities"],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      props.row,
                                      "addingRiskManagementActivities",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "props.row['addingRiskManagementActivities']",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-update-btn", {
                          attrs: {
                            name: "updateBtn",
                            data: _vm.updateBtnData,
                            btnEditable: _vm.btnEditable,
                            flagCondition: _vm.flagCondition,
                          },
                          on: { back: _vm.back },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isCompared,
                              expression: "isCompared",
                            },
                          ],
                          attrs: {
                            label: "이전평가결과조회",
                            icon: "compare_arrows",
                          },
                          on: { btnClicked: _vm.compareScenario },
                        }),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "삭제", icon: "remove" },
                              on: { btnClicked: _vm.removeScenario },
                            })
                          : _vm._e(),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.editable &&
                                !_vm.disabled &&
                                !_vm.btnEditable,
                              expression: "editable&&!disabled&&!btnEditable",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isComplete,
                            url: _vm.completeUrl,
                            param: _vm.process,
                            mappingType: "PUT",
                            label: "평가완료",
                            icon: "check",
                          },
                          on: {
                            beforeAction: _vm.completeProcess,
                            btnCallback: _vm.completeProcessCallback,
                          },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.scenarioViewGroup === "L",
                              expression:
                                "editable&&!disabled&&scenarioViewGroup==='L'",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isSave,
                            url: _vm.saveUrl,
                            param: _vm.process.scenarios,
                            mappingType: "PUT",
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveScenario,
                            btnCallback: _vm.saveScenarioCallback,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm.scenarioViewGroup === "C"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card",
              },
              [
                _c("q-form", { ref: "editForm" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-5" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "상세정보" },
                          },
                          [
                            _c(
                              "template",
                              { slot: "card-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _c("c-btn", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.editable && !_vm.disabled,
                                          expression: "editable && !disabled",
                                        },
                                      ],
                                      attrs: {
                                        isSubmit: _vm.isSaveUnit,
                                        url: _vm.saveUnitUrl,
                                        param: _vm.scenario,
                                        mappingType: "PUT",
                                        label: "저장",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveScenarioUnit,
                                        btnCallback:
                                          _vm.saveScenarioUnitCallback,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      height: "250px",
                                      attachInfo: _vm.attachHazardInfo,
                                      editable:
                                        _vm.editable &&
                                        !_vm.disabled &&
                                        _vm.selectScenario,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "작업 / 평가구분 / 유해위험요인",
                                      name: "scenarioTarget",
                                    },
                                    model: {
                                      value: _vm.scenarioTarget,
                                      callback: function ($$v) {
                                        _vm.scenarioTarget = $$v
                                      },
                                      expression: "scenarioTarget",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "관련법규",
                                      name: "relationLaw",
                                    },
                                    model: {
                                      value: _vm.scenario.relationLaw,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "relationLaw",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.relationLaw",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-checkbox", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      isFlag: true,
                                      label: "위험등록부",
                                      name: "riskbookFlag",
                                    },
                                    model: {
                                      value: _vm.scenario.riskbookFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "riskbookFlag",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.riskbookFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "원인",
                                      name: "riskOccurrenceCause",
                                    },
                                    model: {
                                      value: _vm.scenario.riskOccurrenceCause,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "riskOccurrenceCause",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scenario.riskOccurrenceCause",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "결과",
                                      name: "riskOccurrenceResult",
                                    },
                                    model: {
                                      value: _vm.scenario.riskOccurrenceResult,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "riskOccurrenceResult",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scenario.riskOccurrenceResult",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-datepicker", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      label: "평가일",
                                      name: "assessDate",
                                    },
                                    model: {
                                      value: _vm.scenario.assessDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "assessDate",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.assessDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      data: _vm.scenario,
                                      label: "평가자",
                                      name: "assessUserId",
                                    },
                                    on: {
                                      username: (val) => {
                                        _vm.scenario.assessUserName = val
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.assessUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "assessUserId",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.assessUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "개선정보" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      attachInfo: _vm.attachBeforeInfo,
                                      editable:
                                        _vm.editable &&
                                        !_vm.disabled &&
                                        _vm.selectScenario,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-5" },
                                [
                                  _c("c-textarea", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      rows: 3,
                                      label: "현재안전조치",
                                      name: "currentSafetyMeasures",
                                    },
                                    model: {
                                      value: _vm.scenario.currentSafetyMeasures,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "currentSafetyMeasures",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scenario.currentSafetyMeasures",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-3" },
                                [
                                  _c(
                                    "q-list",
                                    {
                                      attrs: {
                                        dense: "",
                                        bordered: "",
                                        separator: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-risk-info detail-risk",
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$language("빈도")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.scenario
                                                          .beforeFrequency
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("q-separator", {
                                            attrs: { spaced: "", vertical: "" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-risk-info detail-risk",
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$language("강도")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.scenario
                                                          .beforeStrength
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        { staticClass: "detail-risk" },
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$language("위험도")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-section",
                                            {
                                              staticClass: "detail-risk-num",
                                              attrs: { side: "" },
                                            },
                                            [
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.scenario.beforeRisk
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          !_vm.disabled && _vm.selectScenario
                                            ? _c(
                                                "q-item-section",
                                                {
                                                  staticClass:
                                                    "detail-risk-num",
                                                  attrs: { side: "" },
                                                },
                                                [
                                                  _c(
                                                    "q-btn",
                                                    {
                                                      attrs: {
                                                        unelevated: "",
                                                        round: "",
                                                        dense: "",
                                                        size: "10px",
                                                        color: "primary",
                                                        icon: "create",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-popup-proxy",
                                                        {
                                                          ref: "beforeRiskProxy",
                                                        },
                                                        [
                                                          _c(
                                                            _vm.riskPopComponent,
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                width: "800px",
                                                                props: {
                                                                  row: {
                                                                    ramMatrixId:
                                                                      _vm
                                                                        .scenario
                                                                        .ramMatrixId,
                                                                  },
                                                                },
                                                                editable:
                                                                  _vm.editable &&
                                                                  !_vm.disabled &&
                                                                  _vm.selectScenario,
                                                              },
                                                              on: {
                                                                callback: (
                                                                  data,
                                                                  color
                                                                ) =>
                                                                  _vm.callbackCardProxy(
                                                                    data,
                                                                    color,
                                                                    "beforeRiskProxy"
                                                                  ),
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  staticStyle: {
                                    position: "relative",
                                    top: "-10px",
                                  },
                                },
                                [
                                  _c("c-radio", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled || !_vm.selectScenario,
                                      editable: _vm.editable,
                                      comboItems: _vm.improveFlagItems,
                                      label: "개선진행여부",
                                      name: "improveFlag",
                                    },
                                    on: { datachange: _vm.improveFlagChange },
                                    model: {
                                      value: _vm.scenario.improveFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "improveFlag",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.improveFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  staticStyle: {
                                    position: "relative",
                                    top: "-30px",
                                  },
                                },
                                [
                                  _c(
                                    "c-table",
                                    {
                                      attrs: {
                                        title: "개선 목록",
                                        editable:
                                          _vm.editable &&
                                          !_vm.disabled &&
                                          _vm.selectScenario,
                                        columns: _vm.gridImpr.columns,
                                        data: _vm.scenario.imprs,
                                        gridHeight: _vm.gridImpr.height,
                                        usePaging: false,
                                        hideBottom: true,
                                        columnSetting: false,
                                        isFullScreen: false,
                                        filtering: false,
                                        isExcelDown: false,
                                      },
                                      on: {
                                        linkClick: (row) =>
                                          _vm.linkClick(
                                            {
                                              sopImprovementId:
                                                row.sopImprovementId,
                                              ramKrasAssessImprId:
                                                row.ramKrasAssessImprId,
                                            },
                                            row
                                          ),
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "table-button" },
                                        [
                                          _c(
                                            "q-btn-group",
                                            { attrs: { outline: "" } },
                                            [
                                              _vm.editable &&
                                              !_vm.disabled &&
                                              _vm.selectScenario
                                                ? _c("c-btn", {
                                                    attrs: {
                                                      label: "개선요청",
                                                      icon: "add",
                                                    },
                                                    on: {
                                                      btnClicked:
                                                        _vm.openImprRequest,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 row",
                                  staticStyle: {
                                    position: "relative",
                                    top: "-25px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-4" },
                                    [
                                      _c("c-upload-picture", {
                                        attrs: {
                                          isMultiTask: "",
                                          attachInfo: _vm.attachAfterInfo,
                                          editable:
                                            _vm.editable &&
                                            !_vm.disabled &&
                                            _vm.selectScenario &&
                                            _vm.afterRiskEditable,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-5" },
                                    [
                                      _c("c-textarea", {
                                        attrs: {
                                          disabled:
                                            _vm.disabled ||
                                            !_vm.selectScenario ||
                                            !_vm.afterRiskEditable,
                                          editable: _vm.editable,
                                          rows: 3,
                                          label: "추가 리스크관리 계획",
                                          name: "improvementMeasures",
                                        },
                                        model: {
                                          value:
                                            _vm.scenario.improvementMeasures,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scenario,
                                              "improvementMeasures",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scenario.improvementMeasures",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c(
                                        "q-list",
                                        {
                                          attrs: {
                                            dense: "",
                                            bordered: "",
                                            separator: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card-risk-info detail-risk",
                                                },
                                                [
                                                  _c(
                                                    "q-item-section",
                                                    [
                                                      _c("q-item-label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$language(
                                                              "빈도"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "q-item-section",
                                                    { attrs: { side: "" } },
                                                    [
                                                      _c("q-item-label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.scenario
                                                              .afterFrequency
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("q-separator", {
                                                attrs: {
                                                  spaced: "",
                                                  vertical: "",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card-risk-info detail-risk",
                                                },
                                                [
                                                  _c(
                                                    "q-item-section",
                                                    [
                                                      _c("q-item-label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$language(
                                                              "강도"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "q-item-section",
                                                    { attrs: { side: "" } },
                                                    [
                                                      _c("q-item-label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.scenario
                                                              .afterStrength
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item",
                                            { staticClass: "detail-risk" },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$language("위험도")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                {
                                                  staticClass:
                                                    "detail-risk-num",
                                                  attrs: { side: "" },
                                                },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.scenario.afterRisk
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              !_vm.disabled &&
                                              _vm.selectScenario &&
                                              _vm.afterRiskEditable
                                                ? _c(
                                                    "q-item-section",
                                                    {
                                                      staticClass:
                                                        "detail-risk-num",
                                                      attrs: { side: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "q-btn",
                                                        {
                                                          attrs: {
                                                            unelevated: "",
                                                            round: "",
                                                            dense: "",
                                                            size: "10px",
                                                            color: "primary",
                                                            icon: "create",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "q-popup-proxy",
                                                            {
                                                              ref: "afterRiskProxy",
                                                            },
                                                            [
                                                              _c(
                                                                _vm.riskPopComponent,
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    width:
                                                                      "800px",
                                                                    props: {
                                                                      row: {
                                                                        ramMatrixId:
                                                                          _vm
                                                                            .scenario
                                                                            .ramMatrixId,
                                                                      },
                                                                    },
                                                                    editable:
                                                                      _vm.editable &&
                                                                      !_vm.disabled &&
                                                                      _vm.selectScenario,
                                                                  },
                                                                  on: {
                                                                    callback: (
                                                                      data,
                                                                      color
                                                                    ) =>
                                                                      _vm.callbackCardProxy(
                                                                        data,
                                                                        color,
                                                                        "afterRiskProxy"
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-win-pop", {
        ref: "winPopup",
        on: {
          onClose: (val) => _vm.evtCloseWinPopup(val),
          onRecvEvtFromWinPop: (val) => _vm.onRecvWinPop(val),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }